import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import { AbilityContext } from 'casl/Can';
import YesNoIcon from '@mui/icons-material/Flaky';
import Decimal from 'decimal.js';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import CreationForm from './CreationForm';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'deposits';
const table = createColumnHelper();

export default function News() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const [ openCreationForm, setOpenCreationForm ] = useState(false);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canUpdate = ability.can('update', serviceName);
  const canCreate = ability.can('create', serviceName);
  const isManagingCountry = !!user?.managedCountry;

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        ...isManagingCountry ? [
          table.accessor('companyId', {
            id: 'companyId',
            cell: info => info.getValue(),
            header: () => t('Company'),
            footer: props => props.column.id,
          }),
        ] : [],
        table.accessor(row => {
          const { username, companyId } = row;
          return { username, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, companyId } = info.getValue();
            return (<UsernameMenu username={username} companyId={companyId} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor(row => (new Decimal(get(row, `amount.$numberDecimal`, '0')).toFixed(2)), {
          id: 'amount',
          header: () => t('Amount'),
          cell: info => info.getValue(),
          footer: props => props.column.id,
        }),
        table.accessor('designatedRef' , {
          id: 'designatedRef',
          header: () => t('Designated Ref'),
          cell: info => info.getValue(),
          footer: props => props.column.id,
        }),
        table.accessor('skipReward', {
          id: 'skipReward',
          cell: info => {
            const val = info.getValue() || false;
            const color = val === true ? 'success.main' : 'inherit';
            const fontWeight = val === true ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Skip Reward'),
          footer: props => props.column.id,
        }),
        table.accessor('state', {
          cell: info => {
            const val = info.getValue();
            const isDone = val === 'done' ? true : false;
            const isCanceled = val === 'canceled' ? true : false;
            const isCheck = val === 'check' ? true : false;
            const color = isDone ? 'success.main' : isCanceled ? 'error.main' : isCheck ? 'warning.main' : 'inherit';
            const fontWeight = (isDone || isCanceled || isCheck) ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('State'),
          footer: props => props.column.id,
        }),
        table.accessor('transactedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Transacted At'),
          footer: props => props.column.id,
        }),
        table.accessor('executedBy', {
          id: 'executedBy',
          cell: info => info.getValue(),
          header: () => t('Executer'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang, isManagingCountry]
  );

  const handleRowProcess = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenCreationForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Process')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowProcess(data)} color='primary'>
              <YesNoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <CreationForm open={openCreationForm} setOpen={setOpenCreationForm} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Deposits')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={canCreate}
        onCreateClick={handleOnCreateClick}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          designatedRef: false,
          skipReward: true,
        }}
        populate={['bankOption']}
      />
    </Box>
  );
}
