import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import PlusIcon from '@mui/icons-material/AddCircle';
import MinusIcon from '@mui/icons-material/RemoveCircle';
import { useSelector } from 'react-redux';
import { getRemarks } from 'features/feathersStore/selectors';
import Autocomplete from '@mui/material/Autocomplete';
import { transformSavedData } from 'utils/form-utils';
import {
  FormikTextField,
  FormikUsername,
  FormikButton,
} from 'features/formikControl';

const _RNAME = 'walletAdjustments';
const CASL_SUBJECT = 'walletAdjustments';
const SERVICE_NAME = 'wallet-adjustments';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const serviceName = kebabCase(_RNAME);
  const remarks = useSelector(getRemarks(_RNAME));

  const data = useMemo(
    () => {
      if (savedData && savedData._id) return transformSavedData(savedData);

      if (propData && propData._id) {
        const transformedData = transformSavedData(propData);
        if (propData._id === '__template__') {
          const templateData = {
            ...transformedData,
            username: '',
          }

          delete templateData._id;
          return templateData;
        }

        return transformedData;
      }

      return {
        remark: ''
      }
    }, [propData, savedData]
  );

  //const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    username: Yup.string().required(t("Required")),
    balanceType: Yup.string().required(t("Required")),
    type: Yup.string().required(t("Required")),
    amount: Yup.number().required(t("Required")).min(0.01),
    remark: Yup.string().required(t("Required")),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const _id = data._id;
        const flattenVal = flatten(values);
        if (!_id) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [data, serviceName, setGlobalMessage, setGlobalErrorMessage, t]
  );

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Wallet Adjustment')}</DialogTitle>
      <Formik
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikUsername
                      fullWidth
                      id='username'
                      name='username'
                      label={t('Username')}
                      value={values.username || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikButton
                      onClick={() => setFieldValue('type', 'plus')}
                      size='large'
                      fullWidth
                      id='type-plus'
                      name='type'
                      variant={ values.type === 'plus' ? 'contained' : 'outlined' }
                      color={ values.type === 'plus' ? 'success' : 'primary' }
                      startIcon={<PlusIcon />}
                    >
                      {t('plus')}
                    </FormikButton>
                  </Grid>
                  <Grid item xs={6}>
                    <FormikButton
                      onClick={() => setFieldValue('type', 'minus')}
                      size='large'
                      fullWidth
                      id='type-minus'
                      name='type'
                      variant={ values.type === 'minus' ? 'contained' : 'outlined' }
                      color={ values.type === 'minus' ? 'error' : 'primary' }
                      startIcon={<MinusIcon />}
                    >
                      {t('minus')}
                    </FormikButton>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='amount'
                      name='amount'
                      label={t('Amount')}
                      value={values.amount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikButton
                      onClick={() => setFieldValue('balanceType', 'cash')}
                      size='large'
                      fullWidth
                      id='balanceType-cash'
                      name='balanceType'
                      variant={ values.balanceType === 'cash' ? 'contained' : 'outlined' }
                      color={ values.balanceType === 'cash' ? 'warning' : 'primary' }
                    >
                      {t('cash')}
                    </FormikButton>
                  </Grid>
                  <Grid item xs={6}>
                    <FormikButton
                      onClick={() => setFieldValue('balanceType', 'point')}
                      size='large'
                      fullWidth
                      id='balanceType-point'
                      name='balanceType'
                      variant={ values.balanceType === 'point' ? 'contained' : 'outlined' }
                      color={ values.balanceType === 'point' ? 'warning' : 'primary' }
                    >
                      {t('point')}
                    </FormikButton>
                  </Grid>
                  <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    id="autocomplete-remark"
                    value={values.remark || ''}
                    onChange={handleChange}
                    disableClearable
                    autoSelect
                    options={remarks || []}
                    renderInput={(params) => (
                      <FormikTextField
                        {...params}
                        fullWidth
                        id='remark'
                        name='remark'
                        label={t('Remark')}
                        value={values.remark || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
