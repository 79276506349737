import React, { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AbilityContext } from 'casl/Can';
import { transformSavedData } from 'utils/form-utils';
import { getBankOptions  } from 'utils/bank';
import useBankOptions from 'hooks/useBankOptions';

const _NEWID = '@NewClientBank';
const _RNAME = 'clientBanks';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const { data: bankOptions, isLoading: isBankOptionsLoading, error: bankOptionsError } = useBankOptions();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  useEffect(() => {
    if (bankOptionsError) {
      setGlobalErrorMessage({ err: bankOptionsError });
    }
  }, [bankOptionsError, setGlobalErrorMessage, t]);

  const renderBankOptions = useMemo(
    () => {
      if (isBankOptionsLoading) return [];
      return getBankOptions(t, bankOptions);
    }, [isBankOptionsLoading, bankOptions, t]
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');

  const dataSchema = Yup.object().shape({
    accountNumber: Yup.string().required(t("Required")),
    bankName: Yup.string().required(t("Required")),
    userNameAlias: Yup.array().of(Yup.string()).nullable(),
    accountNameAlias: Yup.array().of(Yup.string()).nullable(),
    cashInCount: Yup.number().min(0).integer().nullable(),
    cashInAmount: Yup.number().min(0).nullable(),
    remark: Yup.string().nullable(),
    status: Yup.string().required(t('Required')),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values, { safe: true });

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Client Bank')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('accountNumber')}
                id='accountNumber'
                name='accountNumber'
                label={t('Account Number')}
                value={get(formik, 'values.accountNumber', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.accountNumber`, false) && Boolean(get(formik, `errors.accountNumber`))}
                helperText={get(formik, `touched.accountNumber`, false) && get(formik, `errors.accountNumber`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.bankName', false) && Boolean(get(formik, 'errors.bankName'))}
              >
                <InputLabel id='bank-name-select-label'>{t('Bank Name')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('bankName')}
                  labelId='bank-name-select-label'
                  id='bankName'
                  name='bankName'
                  value={get(formik, 'values.bankName', 'None')}
                  label={t('Bank Name')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    renderBankOptions
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.bankName', false) && get(formik, 'errors.bankName')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('accountNameAlias')}
                id='accountNameAlias'
                name='accountNameAlias'
                label={t('Account Name Alias')}
                multiline
                rows={3}
                value={get(formik, 'values.accountNameAlias', []).join('\n')}  // Join the array of strings with a newline character
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const value = event.target.value;
                  const valuesArray = value === '' ? [] : value
                    .split('\n')
                    .map((val) => val.trim());
                  formik.handleChange({
                    target: {
                      name: 'accountNameAlias',
                      value: valuesArray,
                    },
                  });
                }}
                error={get(formik, `touched.accountNameAlias`, false) && Boolean(get(formik, `errors.accountNameAlias`))}
                helperText={get(formik, `touched.accountNameAlias`, false) && get(formik, `errors.accountNameAlias`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('usernameAlias')}
                id='usernameAlias'
                name='usernameAlias'
                label={t('Username Alias')}
                multiline
                rows={3}
                value={get(formik, 'values.usernameAlias', []).join('\n')}  // Join the array of strings with a newline character
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const value = event.target.value;
                  const valuesArray = value === '' ? [] : value
                    .split('\n')
                    .map((val) => val.trim());
                  formik.handleChange({
                    target: {
                      name: 'usernameAlias',
                      value: valuesArray,
                    },
                  });
                }}
                error={get(formik, `touched.usernameAlias`, false) && Boolean(get(formik, `errors.usernameAlias`))}
                helperText={get(formik, `touched.usernameAlias`, false) && get(formik, `errors.usernameAlias`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('cashOutCount')}
                id='cashOutCount'
                name='cashOutCount'
                label={t('Cash Out Count')}
                value={get(formik, 'values.cashOutCount', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.cashOutCount`, false) && Boolean(get(formik, `errors.cashOutCount`))}
                helperText={get(formik, `touched.cashOutCount`, false) && get(formik, `errors.cashOutCount`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('cashOutAmount')}
                id='cashOutAmount'
                name='cashOutAmount'
                label={t('Cash Out Amount')}
                value={get(formik, 'values.cashOutAmount', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.cashOutAmount`, false) && Boolean(get(formik, `errors.cashOutAmount`))}
                helperText={get(formik, `touched.cashOutAmount`, false) && get(formik, `errors.cashOutAmount`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('remark')}
                id='remark'
                name='remark'
                label={t('Remark')}
                value={get(formik, 'values.remark', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.remark`, false) && Boolean(get(formik, `errors.remark`))}
                helperText={get(formik, `touched.remark`, false) && get(formik, `errors.remark`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.status', false) && Boolean(get(formik, 'errors.status'))}
              >
                <InputLabel id='status-select-label'>{t('Status')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('status')}
                  labelId='status-select-label'
                  id='status'
                  name='status'
                  value={get(formik, 'values.status', 'None')}
                  label={t('Status')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    ['unverified', 'suspicious', 'blocked', 'verified'].map(o => (
                      <MenuItem key={o} value={o}>
                        {t(o)}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.status', false) && get(formik, 'errors.status')}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
