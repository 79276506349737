import React, { useContext, useMemo, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import { AbilityContext } from 'casl/Can';
import { useAuth } from 'hooks/useAuth';
import feathers from 'services/feathers';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function FormikUsername(props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ field, meta ] = useField(props);
  const [ options, setOptions ] = useState([]);
  const { values, setFieldValue, initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const companyId = useMemo(() => user.companyId, [user]);

  const username = values.username;
  const { caslSubject = '', _id } = initialValues;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, 'username');

  useEffect(() => {
    if (!isAllowed) {
      return;
    }

    if (!companyId || !username) {
      setOptions([]);
      return;
    }

    const throttleGetClientBank = throttle(async () => {
      const userService = feathers.service('users');
      const users = await userService.find({
        query: {
          companyId,
          username: { $regex: username, $options: 'i' },
        }
      });

      if (!users.data.length) {
        setOptions([]);
        return;
      }

      setOptions(users.data.map((user) => {
        const { _id, username } = user;
        return { _id, username };
      }));
    }, 1000);

    throttleGetClientBank();
  }, [companyId, username, isAllowed]);

  if (!isAllowed) {
    return (
      <TextField
        { ...field }
        { ...props }
        disabled
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    );
  }

  return (
    <Autocomplete
      getOptionLabel={(option) => {
        if (!option) {
          return '';
        } else if (typeof option === 'string') {
          return option;
        } else if (typeof option === 'object') {
          return option?.username || '';
        }
        return '';
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={values.username}
      noOptionsText={t('User not found')}
      onChange={(event, newValue) => {
        if (!newValue) {
          setFieldValue('username', '');
          return;
        }
        if (typeof newValue === 'object') {
          setFieldValue('username', newValue.username);
        } else {
          setFieldValue('username', newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFieldValue('username', newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          { ...field }
          { ...props }
          { ...params }
          disabled={isAllowed === false}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
}
