import { get, groupBy, orderBy } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Decimal from 'decimal.js';
import CompanyBankMenuItemBox from 'features/companyBanks/CompanyBankMenuItemBox';

export function getFullBankLabel(bankName, bankOption = {}) {
  const humanBankName = get(bankOption, 'name.en', '');
  const group = get(bankOption, 'group', '');
  const isNotBankGroup = group !== 'Bank';

  const nameArray = [
    ...isNotBankGroup ? [group] : [],
    humanBankName || bankName,
  ];

  return nameArray.filter(Boolean).join(' - ');
}

export function getBankLabel(bankOption, lang) {
  const primaryName = get(bankOption, `name.${lang}`, '');
  const secondaryName = get(bankOption, `name.en`, '');
  return primaryName || secondaryName;
}

export function getBankOptions(t, bankOptions) {
  const sortedBankOptions = orderBy(bankOptions, ['group', 'name.en'], ['asc', 'asc']);
  const groupByType = groupBy(sortedBankOptions, 'group');

  function renderMenuItem({ _id, name, code }) {
    return (
      <MenuItem key={_id} value={code}>
        {name.en}
      </MenuItem>
    );
  }

  return Object.entries(groupByType).flatMap(([key, items]) => {
    const keyLabel = key === 'Bank' ? t(key) : key;
    return[
      <ListSubheader key={key}>{keyLabel}</ListSubheader>,
      ...items.map(renderMenuItem),
  ]});
}

export function getCompanyBankOptions(t, companyBanks) {
  if (!companyBanks) return [];

  const sortedBankOptions = orderBy(companyBanks, ['bankOption.group', 'name'], ['asc', 'asc']);
  const groupByType = groupBy(sortedBankOptions, 'bankOption.group');

  function renderMenuItem(item, t) {
    const balance = new Decimal(get(item, 'data.balance.$numberDecimal', '0')).toFixed(2);
    return (
      <MenuItem key={item._id} value={item._id}>
        <Paper variant='outlined' sx={{ p: 1.5, minWidth: '280px' }}>
          <CompanyBankMenuItemBox name={item.name} displayNumber={item.displayNumber} balance={balance} />
        </Paper>
      </MenuItem>
    );
  }

  return Object.entries(groupByType).flatMap(([key, items]) => [
    <ListSubheader key={key}>{t(key)}</ListSubheader>,
    ...items.map(item => renderMenuItem(item, t)),
  ]);
}
