import { useEffect } from 'react';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import useSWR from 'swr';

export default function useFeathersGet(serviceName, idToGet) {
  const service = feathers.service(`/${serviceName}`);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const {
    data,
    error,
    isLoading
  } = useSWR(`${serviceName}/${idToGet}`, () => {
    if (!idToGet) return null;
    return service.get(idToGet);
  });

  useEffect(() => {
    if (error) {
      setGlobalErrorMessage({ err: error });
    }
  }, [error, setGlobalErrorMessage]);

  return {
    data,
    ready: !isLoading
  };
}
