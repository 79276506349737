import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BoolToggle from 'features/boolToggle/BoolToggle';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { getCountryOptions } from 'utils/country';
import { getBankLabel, getBankOptions } from 'utils/bank';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';
import { bankPurposesLookup, bankPurposeEnums } from 'lookups/banks';
import useBankOptions from 'hooks/useBankOptions';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

const _RNAME = 'companyBanks';

export default function Filter(props) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { open, setOpen, userCompanyId } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));

  const hasCountryOption = useMemo(
    () => {
      return userCompanyId === '*';
    }, [userCompanyId]
  );

  const countryOptions = useMemo(
    () => {
      return getCountryOptions();
    }, []
  );

  const purposesOptions = useMemo(
    () => {
      return bankPurposeEnums.map((purpose) => {
        const translatedPurpose = t(bankPurposesLookup[purpose]);
        return (
          <MenuItem key={purpose} value={purpose}>{translatedPurpose}</MenuItem>
        );
      });
    }, [t]
  );

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    name: Yup.string().nullable(),
    bankName: Yup.string().nullable(),
    accountName: Yup.string().nullable(),
    displayNumber: Yup.string().nullable(),
    accountNumber: Yup.string().nullable(),
    purposes: Yup.string().oneOf(bankPurposeEnums).nullable(),
    isHidden: Yup.bool().nullable(),
    isEnabled: Yup.bool().nullable(),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const selectedCountry = useMemo(
    () => {
      return get(formik, 'values.country');
    }, [formik]
  );

  const { data: bankOptions, isLoading: isBankOptionsLoading, error: bankOptionsError } = useBankOptions(selectedCountry);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  useEffect(() => {
    if (bankOptionsError) {
      setGlobalErrorMessage({ err: bankOptionsError });
    }
  }, [bankOptionsError, setGlobalErrorMessage]);

  const renderBankOptions = useMemo(
    () => {
      if (isBankOptionsLoading) return [];
      return getBankOptions(t, bankOptions);
    }, [isBankOptionsLoading, bankOptions, t]
  );

  const selectedBankName = useMemo(
    () => {
      return get(formik, 'values.bankName', '');
    }, [formik]
  );

  const selectedBankOption = useMemo(
    () => {
      if (!bankOptions || !selectedBankName) return null;
      return bankOptions.find((option) => option.code === selectedBankName);
    }, [bankOptions, selectedBankName]
  );

  const isNotBankGroup = useMemo(
    () => {
      // For 'None' option
      if (!selectedBankOption) return false;

      const { group } = selectedBankOption || {};

      return group !== 'Bank';
    }, [selectedBankOption]
  );

  const isAuBank = useMemo(
    () => {
      return selectedBankOption?.country === 'au';
    }, [selectedBankOption]
  );
  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(setFilter(_RNAME, {
      isHidden: false,
    }));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            {
              hasCountryOption &&
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={get(formik, 'touched.country', false) && Boolean(get(formik, 'errors.country'))}
                  >
                    <InputLabel id='country-select-label'>{t('Country')}</InputLabel>
                    <Select
                      autoWidth
                      labelId='country-select-label'
                      id='country'
                      name='country'
                      value={selectedCountry || 'None'}
                      label={t('Country')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                      {
                        countryOptions
                      }
                    </Select>
                    <FormHelperText>{get(formik, 'touched.country', false) && get(formik, 'errors.country')}</FormHelperText>
                  </FormControl>
                </Grid>
            }
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='name'
                name='name'
                label={t('Name')}
                value={get(formik, 'values.name', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.name`, false) && Boolean(get(formik, `errors.name`))}
                helperText={get(formik, `touched.name`, false) && get(formik, `errors.name`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.bankName', false) && Boolean(get(formik, 'errors.bankName'))}
              >
                <InputLabel id='bank-name-select-label'>{t('Bank Name')}</InputLabel>
                <Select
                  autoWidth
                  labelId='bank-name-select-label'
                  id='bankName'
                  name='bankName'
                  value={selectedBankName || 'None'}
                  label={t('Bank Name')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    renderBankOptions
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.bankName', false) && get(formik, 'errors.bankName')}</FormHelperText>
              </FormControl>
            </Grid>
            {
              isNotBankGroup &&
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='displayNumber'
                  name='displayNumber'
                  label={getBankLabel(selectedBankOption, lang)}
                  value={get(formik, 'values.displayNumber', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, `touched.displayNumber`, false) && Boolean(get(formik, `errors.displayNumber`))}
                  helperText={get(formik, `touched.displayNumber`, false) && get(formik, `errors.displayNumber`)}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='accountName'
                name='accountName'
                label={t('Account Name')}
                value={get(formik, 'values.accountName', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.accountName`, false) && Boolean(get(formik, `errors.accountName`))}
                helperText={get(formik, `touched.accountName`, false) && get(formik, `errors.accountName`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='accountNumber'
                name='accountNumber'
                label={isAuBank ? `Bsb + ${t('Account Number')}` : t('Account Number')}
                value={get(formik, 'values.accountNumber', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.accountNumber`, false) && Boolean(get(formik, `errors.accountNumber`))}
                helperText={get(formik, `touched.accountNumber`, false) && get(formik, `errors.accountNumber`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.purposes', false) && Boolean(get(formik, 'errors.purposes'))}
              >
                <InputLabel id='purposes-select-label'>{t('Purposes')}</InputLabel>
                <Select
                  autoWidth
                  labelId='purposes-select-label'
                  id='purposes'
                  name='purposes'
                  value={get(formik, 'values.purposes', '')}
                  label={t('Purposes')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {
                    purposesOptions
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.purposes', false) && get(formik, 'errors.purposes')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <BoolToggle
                    value={get(formik, 'values.isHidden', null)}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('isHidden', newValue)
                    }}
                  />
                }
                label={t('Hidden')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <BoolToggle
                    value={get(formik, 'values.isEnabled', null)}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('isEnabled', newValue)
                    }}
                  />
                }
                label={t('Enabled')}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
