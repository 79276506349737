import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { AbilityContext } from 'casl/Can';
import {
  Button,
} from '@mui/material';

export default function FormikButton(props) {
  const [ field ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName } = field;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);

  return (
    <Button
      disabled={!isAllowed}
      {...field}
      {...props}
    />
  )
}