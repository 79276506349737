import useSWR from 'swr';
import feathers from 'services/feathers';

export default function useCompanyBanks(purposes = ['deposit', 'withdrawal']) {

  const { data, error, isLoading, mutate } = useSWR(`company-banks`, async () => {
    const response = await feathers.service('company-banks').find({
      query: {
        purposes: { $in: purposes },
        isHidden: false,
        $limit: -1,
        $populate: [{
          'path': 'data',
        }, {
          'path': 'bankOption',
        }],
      },
    });

    return response;
  });

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}