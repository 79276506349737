import React, { useMemo, useContext, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { AbilityContext } from 'casl/Can';
import { getFullBankLabel } from 'utils/bank';
import useSWR, { mutate } from 'swr';
import { CircularProgress } from '@mui/material';
import { get } from 'lodash';

export default function BankInformation({ username, companyId }) {
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const ability = useContext(AbilityContext);
  const {
    data,
    isLoading,
  } = useSWR(`wallets/${companyId}/${username}`, () => feathers.service('wallets').find({
    query: {
      username,
      companyId,
      $limit: 1,
      $populate: ['bankOption']
    }
  }), { revalidateOnFocus: true });

  const walletData = useMemo(
    () => {
      return data?.data[0] || {};
    }, [data]
  );

  const canDeleteSavedBank = useMemo(
    () => {
      return ability.can('delete', 'wallets', 'savedBank');
    }, [ability]
  );

  const savedBank = useMemo(
    () => {
      return walletData?.savedBank || {};
    }, [walletData]
  );

  const hasBsb = useMemo(
    () => {
      return walletData?.bankOption?.hasBsb;
    }, [walletData]
  );

  const formattedBsb = useMemo(
    () => {
      if (!hasBsb) {
        return '';
      }

      return `${savedBank.accountNumber.slice(0, 6)}`;
    }, [savedBank.accountNumber, hasBsb]
  );

  const formattedAccountNumber = useMemo(
    () => {
      if (!savedBank.accountNumber) {
        return '';
      }

      if (hasBsb) {
        return `${savedBank.accountNumber.slice(6)}`;
      }

      return savedBank.accountNumber;
    }, [savedBank.accountNumber, hasBsb]
  );

  const humanBankName = useMemo(
    () => {
      const bankName = get(walletData, 'savedBank.bankName', '');
      const bankOption = get(walletData, 'bankOption', {});
      return getFullBankLabel(bankName, bankOption);
    }, [walletData]
  );

  const handleDeleteSavedBank = useCallback(
    async (event) => {
      event?.preventDefault();
      const { _id } = walletData;

      try {
        await feathers.service('wallets').patch(_id, {
          $unset: {
            'savedBank': 1
          }
        });
        mutate(`wallets/${companyId}/${username}`);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [walletData, setGlobalErrorMessage, companyId, username]
  );

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ fontWeight: 700 }} variant='h6'>
          {t('Bank Information')}
        </Typography>
        {
          canDeleteSavedBank && (
            <Button disabled={!savedBank?.accountNumber} onClick={handleDeleteSavedBank} size='small' color='error' variant="contained" startIcon={<DeleteIcon />}>
              {t('Delete')}
            </Button>
          )
        }
      </Box>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: humanBankName ? 'inherit' : 'text.disabled',
          fontStyle: humanBankName ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {t('Bank Name')}
            <span>{isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}</span>
          </Box>
        </span>

        <span>{humanBankName || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: savedBank.accountName ? 'inherit' : 'text.disabled',
          fontStyle: savedBank.accountName ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Account Name')}</span>
        <span>{savedBank.accountName || t('TBD')}</span>
      </Typography>
      {
        hasBsb && (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'inherit',
              fontStyle: 'inherit',
            }}
            variant='subtitle1'
          >
            <span>{'BSB'}</span>
            <span>{formattedBsb}</span>
          </Typography>
        )
      }
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: savedBank.accountNumber ? 'inherit' : 'text.disabled',
          fontStyle: savedBank.accountNumber ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Account Number')}</span>
        <span>{formattedAccountNumber || t('TBD')}</span>
      </Typography>
    </Paper>
  );
}
