import { useMemo } from 'react';
import useSWR from 'swr';
import feathers from 'services/feathers';
import { useAuth } from 'hooks/useAuth';

export default function useBankOptions(country) {
  const { user } = useAuth();
  const { country: userCountry, managedCountry: userManagedCountry } = user;

  const chosenCountry = useMemo(() => {
    return userManagedCountry || country || userCountry
  }, [country, userCountry, userManagedCountry]);

  const { data, error, isLoading } = useSWR(`bank-options/${chosenCountry}`, async () => {
    if (!chosenCountry) return;

    const response = await feathers.service('bank-options').find({
      query: {
        country: chosenCountry,
        $limit: -1,
      },
    });

    return response;
  });

  return {
    data,
    isLoading,
    error,
  };
}